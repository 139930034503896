import React from "react";

import Container from "react-bootstrap/Container";

import Page from "components/Page/Page";

const config = [
  {
    name: "Make a submission heading",
    moduleComponentName: "SectionHeading",
    rowProps: {
      className: "py-5 bg-primary text-light",
      noGutters: true,
    },
    moduleProps: {
      heading: {
        priority: 2,
        text: "Make a submission",
      },
      subheading: {
        text: `
        The Sydney Metro – Western Sydney Airport Environmental Impact Statement
        is no longer accepting submissions.
        `,
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Section Intro",
    rowProps: {
      noGutters: true,
      className: "pt-5",
    },
    children: [
      {
        name: "Section Intro Text",
        moduleComponentName: "BaseMarkdownRenderer",
        moduleProps: { name: "submission-intro" },
      },
    ],
    wrapWithContainer: true,
  },
  {
    name: "Make a submission body",
    children: [
      {
        name: "Submission Method 1",
        columnProps: {
          xs: 12,
          md: 4,
        },
        moduleComponentName: "BaseMarkdownRenderer",
        moduleProps: { name: "method-1" },
      },
      {
        name: "Submission Method 2",
        columnProps: {
          xs: 12,
          md: 4,
          className:
            "my-4 my-sm-0 py-4 py-sm-0 border-top-primary border-bottom-primary border-md-left-primary border-md-right-primary border-md-top-0 border-md-bottom-0",
        },
        moduleComponentName: "BaseMarkdownRenderer",
        moduleProps: { name: "method-2" },
      },
      {
        name: "Submission Method 3",
        columnProps: {
          xs: 12,
          md: 4,
        },
        moduleComponentName: "BaseMarkdownRenderer",
        moduleProps: { name: "method-3" },
      },
    ],
    rowProps: {
      noGutters: true,
      className: "my-3 my-sm-5",
    },
    wrapWithContainer: true,
  },
];

export const MakeASubmission = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default MakeASubmission;
